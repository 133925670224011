import { effect, inject, Injectable, signal } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, map, retry } from 'rxjs';
import { Guid } from 'guid-typescript';

import { environment } from '@environments/environment';
import { UserService } from './user.service';

import { IPushRegistrationData } from '@core/interfaces/notifications.interface';
import { getMessaging, getToken } from '@firebase/messaging';
import { KeycloakEvent, KeycloakService } from '@core/services/auth/keycloak.service';
import keycloak, { KeycloakTokenParsed } from 'keycloak-js';

@Injectable({
  providedIn: 'root',
})
export class AuthSsoService {
  private _tokenParsed: KeycloakTokenParsed | undefined = {};
  private _httpClientWithoutInterceptor: HttpClient;
  private _updateTokenInterval: any;

  private keycloakInstance: keycloak;
  private keycloakService = inject(KeycloakService);

  private static readonly tokenForcedRefreshTimeIntervalMs: number = 240000;
  private static readonly clientName = 'cs_chargingservices_frontend';

  pushToken: string = '';
  fdGwToken: string | undefined = undefined;

  fdGWTokenSignal = signal<string>('');
  isLoggedIn = signal<boolean>(false);

  constructor(
    httpBackend: HttpBackend,
    private userService: UserService,
  ) {
    // i mean this likely needs to be refactored whole setting on token logic and where to do it
    this.fdGwToken = this.keycloakService.getToken();
    if (this.fdGwToken) this.fdGWTokenSignal.set(this.fdGwToken);

    this._httpClientWithoutInterceptor = new HttpClient(httpBackend);

    effect(
      () => {
        return this.handleKeycloakEvent(this.keycloakService.keyCloakEventSignal());
      },
      { allowSignalWrites: true },
    );
  }

  handleKeycloakEvent(event: KeycloakEvent | null): void {
    if (event)
      switch (event.type) {
        case 'onReady':
          console.log('[authService] Keycloak event: OnReady, Authenticated?:', event.authenticated);
          break;

        case 'onAuthSuccess':
          console.log('[authService] Keycloak event onAuthSuccess');
          this.onSuccessfulKeycloakLogin();
          break;

        case 'onAuthError':
          console.error('[authService] Keycloak event: OnAuthError', event.error);
          this.onKeycloakAuthError(event);
          break;

        case 'onAuthRefreshSuccess':
          console.log('[authService] Keycloak event.type: OnAuthRefreshSuccess');
          break;

        case 'onAuthRefreshError':
          console.error('[authService] Keycloak event: OnAuthRefreshError', event);
          break;

        case 'onAuthLogout':
          console.log('[authService] Keycloak event: OnAuthLogout', event);
          this.logout('KeycloakEventType.OnAuthLogout').then(() => {
            console.log('[authService] logout completed');
          });
          break;

        case 'onTokenExpired':
          console.warn('Token expired. Attempting to refresh...');
          this.refreshToken().then(() => {
            console.log('[authService] token refreshed on expiry.');
          });
          break;

        default:
          console.log('Unhandled Keycloak event:', event);
      }
  }

  private onKeycloakAuthError(event: KeycloakEvent): void {
    console.log('[authService] Keycloak auth error');
  }

  /**
   * Refresh the Keycloak token and create a new token
   *
   * @returns boolean flag indicating if refreshToken and token creation is completed successfully or not
   */
  public async refreshToken(): Promise<boolean> {
    let tokenRefreshed = false;

    try {
      tokenRefreshed = await this.keycloakInstance.updateToken(-1); // -1: to force refresh the token
      this._tokenParsed = this.keycloakService.getKeyCloakInstance().tokenParsed;
    } catch (error) {
      // await this.logout(`Error in refreshing the token: ${error}`);
      return false;
    }

    if (!tokenRefreshed) {
      return false;
    }

    this.fdGwToken = await this.keycloakService.getToken();
    if (this.fdGwToken) {
      return true;
    }

    return false;
  }

  dispatchNavbarTokens() {
    // dispatch navbar event when token is refreshed
    document.dispatchEvent(
      new CustomEvent('navbar_RefreshToken', {
        detail: this.fdGwToken,
      }),
    );

    document.dispatchEvent(
      new CustomEvent('navbar_UpdateToken', {
        detail: this.fdGwToken,
      }),
    );
  }

  /**
   * boolean flag indicating if Keycloak token is expired or not
   *
   * @returns boolean flag indicating if Keycloak token is expired or not
   * @description If keycloak is not initialized then this function will return false!
   */
  public isKeycloakTokenExpired(): boolean {
    if (this._tokenParsed == null) {
      return false;
    }
    try {
      const tokenExpiryTimeEpoch = this._tokenParsed.exp || -1;
      const expireInSeconds = Math.round(tokenExpiryTimeEpoch - new Date().getTime() / 1000);
      return expireInSeconds <= 0 ? true : false;
    } catch (error) {
      console.error('[authService] failed to get token expiry.', error);
      return false;
    }
  }

  /**
   * logout the user
   *
   * @param reason - logout reason
   */
  public async logout(reason: string, localhost = false): Promise<void> {
    console.log('[authService] logout - reason: ' + reason);
    this.notificationDeregistration().subscribe({
      error: (err) => {
        console.log(err);
      },
    });
    this.isLoggedIn.set(false);
    this.userService.clearUser();
    window.localStorage.removeItem('tokenRegistered');
    // Remove stored filters in rtm session list page
    window.localStorage.removeItem('datesFilterSelection');
    window.localStorage.removeItem('deviationSelection');
    window.localStorage.removeItem('vehicleSelection');
    window.sessionStorage.clear();
    await this.keycloakService.logout();
  }

  /**
   * method invoked after successful keycloak login
   */
  private async onSuccessfulKeycloakLogin(): Promise<void> {
    // todo
    this._tokenParsed = this.keycloakService.getKeyCloakInstance().tokenParsed;

    let token = await this.keycloakService.getToken();

    if (token) {
      this.fdGwToken = token;
      this.isLoggedIn.set(true);
      this.fdGWTokenSignal.set(token);

      this.userService.decodeTokenAndSetUserData(token).subscribe();
      this.registerFirebasePushToken();
      this.dispatchNavbarTokens();
    }
  }

  registerFirebasePushToken(): void {
    const messaging = getMessaging();

    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.pushToken = currentToken;
          const pushData = { pushToken: currentToken, pushTokenProvider: environment.pushTokenProvider };

          if (!window.localStorage.getItem('tokenRegistered')) {
            this.notificationRegistration(pushData).subscribe({
              error: (err) => {
                console.log(err);
              },
            });
            window.localStorage.setItem('tokenRegistered', 'true');
          }
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  notificationRegistration(pushData: IPushRegistrationData) {
    const headers = this.getHeaders();
    const url = environment.pushPlatform + `v1/registration/${environment.appId}/${this.pushToken}`;
    return this._httpClientWithoutInterceptor.post(url, pushData, { headers: headers });
  }

  notificationDeregistration() {
    const url = environment.pushPlatform + `v1/registration/${environment.appId}/${this.pushToken}`;
    const headers = this.getHeaders();
    return this._httpClientWithoutInterceptor.delete(url, { headers: headers });
  }

  getHeaders() {
    let headers = new HttpHeaders();

    const token = this.keycloakService.getToken();
    headers = headers.set('authorization', `Bearer ${this.fdGwToken}`);
    headers = headers.set('x-client', 'cs_chargingservices_frontend');
    headers = headers.set('X-Correlation-ID', Guid.create().toString());
    return headers;
  }
}
